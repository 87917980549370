import { graphql, PageProps } from "gatsby";
import React from "react";
import { Layout } from "../../components/layout";
import { Box } from "../../components/rebrand/core/box";
import { HeroSection } from "../../components/sections/hero";
import { ZigZagSection } from "../../components/rebrand/sections/ZigZag/ZigZag";
import { SEO } from "../../components/seo";
import {
    IoTMeterData,
    ZigZagSectionDataIoTMeter
} from "../../data/products";


export const pageQuery = graphql`
  query IoTMeterPage {
    site {
      siteMetadata {
        pages {
          products {
            iotMeter {
                title
                description
                keywords
            }
          }
        }
      }
    }
  }
`;

interface IoTMeterPageProps extends PageProps {
    data: Queries.IoTMeterPageQuery; 
}


export default function ProductIoTMeter({ location: { pathname }, data: { site } }: IoTMeterPageProps) {

    const { title, description, keywords } = site?.siteMetadata?.pages?.products?.iotMeter as Queries.SiteSiteMetadataPagesProductsPlannycs;

    const seo = {
        title: title as string,
        description: description as string,
        // keywords: keywords as [],
        pathname,
    };


    return (
        <Layout>
            <SEO {...seo} />
            <HeroSection {...IoTMeterData}>
                <Box display="flex" flexDirection="column">
                    <Box height={[6, 12, 26]} />
                </Box>
            </HeroSection>
            <ZigZagSection {...ZigZagSectionDataIoTMeter} />
        </Layout>
    );
}
