import React from "react";
import { PropsWithChildren, ReactNode } from "react";
import styled from "styled-components";
import { ButtonStyleProps } from "styled-system";
import { Box, BoxProps } from "../../rebrand/core/box";
import { Button } from "../../rebrand/core/Button/Button";
import { Grid } from "../../rebrand/core/Grid/Grid";
import { Typography } from "../../rebrand/core/Typography/Typography";
import { Section } from "../../rebrand/sections/Section/Section";

const Gradient = styled(Box).attrs(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
}))``;

const Mask = styled(Box).attrs(() => ({
  zIndex: 1,
  position: "absolute",
  left: 0,
  bottom: 0,
  width: "100%",
  height: "40%",
}))``;

export const GraphicsContainer = styled(Box).attrs(() => ({
  display: ["none", "block"],
}))`
  width: 100%;
`;

export interface HeroSectionProps {
  title: string;
  size?: "regular" | "large";
  gradient?: {
    main: BoxProps;
    fade: BoxProps;
  };
  secondaryButton?: ButtonStyleProps;
  subtitle: string;
  graphics: ReactNode;
  graphicsPosition?: "top" | "right";
}

export function HeroSection(props: PropsWithChildren<HeroSectionProps>) {
  const {
    title,
    subtitle,
    children,
    graphics,
    graphicsPosition = "top",
    secondaryButton,
    gradient = {
      main: {
        backgroundImage:
          "radial-gradient(circle at top right, hsl(225 90% 95%), hsl(225 90% 95% / 0%)), radial-gradient(circle at bottom left, hsl(244 72% 92%), hsl(244 72% 92% / 0%))",
      },
      fade: {
        backgroundImage:
          "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 60%, #ffffff 100%)",
      },
    },
    size = "regular",
  } = props;

  return (
    <Section paddingTopSpacing={[17, 19]} disableGutters={false} position="relative">
      <Grid>
        <Gradient {...gradient.main} />
        <Mask {...gradient.fade} />
        <Box position="relative" zIndex={2}>
          <Grid>
            {graphicsPosition === "top" && <Box gridColumn="1 / span 12">{graphics}</Box>}

            <Box
              gridColumn={["1 / span 12", "1 / span 12", `1 / span ${graphicsPosition === "right" ? 6 : 12}`]}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Typography
                variant={size === "large" ? "h1" : "h2"}
                maxWidth="34rem"
                marginTopSpacing={[0, graphicsPosition === "top" ? 10 : 0]}
              >
                {title}
              </Typography>
              <Typography
                variant="body1"
                marginTopSpacing={graphicsPosition === "top" ? 1 : 4}
                marginBottomSpacing={4}
                maxWidth="36.25rem"
              >
                {subtitle}
              </Typography>
              <Box>
                <Button
                  variant="secondary"
                  size="large"
                  marginBottom={3}
                  marginLeft={[0, 0, "0px"]}
                  href="/demo/"
                  width={["100%", "100%", "auto"]}
                  {...secondaryButton}
                >
                  ¿Te interesa este producto?
                </Button>
              </Box>
            </Box>

            {graphicsPosition === "right" && (
              <Box gridColumn={["1 / span 12", "1 / span 12", "7 / span 6"]}>{graphics}</Box>
            )}

            <Box gridColumn="1 / span 12">{children}</Box>
          </Grid>
        </Box>
      </Grid>
    </Section>
  );
}

HeroSection.defaultProps = {
  title: "Secure access to private data for your distributed workforce",
  subtitle: "Replace corporate VPNs with a more secure, usable and performant zero trust access solution",
};
