import { links } from "../site";

export type PlanEntry = "Starter" | "Teams" | "Business" | "Enterprise";
type Price = "Free" | number | "Custom";

export type PlanInfo = {
  title: PlanEntry;
  subtitle: string;
  accent: string;
  cta: {
    label: string;
    outlined?: boolean;
    noCC?: boolean;
    path: string;
  };
  annual: Price;
  monthly: Price;
  highlights: { label: string }[];
  keyFeatures: { label: string }[];
};
export type Plan = Record<PlanEntry, PlanInfo>;

export const plan: Plan = {
  Starter: {
    title: "Starter",
    subtitle: "For individuals and small teams that need to replace a VPN for remote access",
    accent: "#8B33FB",
    cta: {
      label: "Get Started",
      noCC: false,
      path: "#",
    },
    annual: "Free",
    monthly: "Free",
    highlights: [
      {
        label: "Up to 5 users",
      },
      {
        label: "Up to 1 device / person",
      },
      {
        label: "Up to 2 remote networks",
      },
    ],
    keyFeatures: [
      {
        label: "Works on NAS, Raspberry Pi, Cloud VMs",
      },
      {
        label: "Remote access to media servers, home automation",
      },
      {
        label: "Native clients on Android, Linux, Mac, Windows",
      },
    ],
  },
  Teams: {
    title: "Teams",
    subtitle: "For small teams that need to replace a VPN for remote access",
    accent: "#3957FF",
    cta: {
      label: "Start 14-day trial",
      noCC: false,
      path: "#",
    },
    annual: 5,
    monthly: 6,
    highlights: [
      {
        label: "Up to 50 users",
      },
      {
        label: "Up to 2 devices / person",
      },
      {
        label: "Up to 5 remote networks",
      },
    ],
    keyFeatures: [
      {
        label: "Network-level access policies",
      },
      {
        label: "Email support",
      },
    ],
  },
  Business: {
    title: "Business",
    subtitle: "For larger teams that need more advanced access controls",
    accent: "#121315",
    cta: {
      label: "Start 14-day Trial",
      noCC: true,
      path: "#",
    },
    annual: 10,
    monthly: 12,
    highlights: [
      {
        label: "Up to 150 users",
      },
      {
        label: "Up to 5 devices / person",
      },
      {
        label: "Up to 10 remote networks",
      },
    ],
    keyFeatures: [
      {
        label: "Resource-level access policies",
      },
      {
        label: "Identity provider integration",
      },
      {
        label: "Email support",
      },
    ],
  },
  Enterprise: {
    title: "Enterprise",
    subtitle: "For organizations that need comprehensive access controls, detailed auditing, and deployment automation",
    accent: "#121315",
    cta: {
      label: "Talk to Sales",
      outlined: true,
      path: links.contact.path,
    },
    annual: "Custom",
    monthly: "Custom",
    highlights: [
      {
        label: "Unlimited users",
      },
      {
        label: "Unlimited devices",
      },
      {
        label: "Unlimited remote networks",
      },
    ],
    keyFeatures: [
      {
        label: "Service accounts",
      },
      {
        label: "Device posture",
      },
      {
        label: "Network analytics",
      },
      {
        label: "Priority support",
      },
    ],
  },
};

export const orderedPlans = [plan.Starter, plan.Teams, plan.Business, plan.Enterprise];
