
import React from "react";
import HeroGraphic from "../components/graphics/zero-trust/hero";
import { ComparisonMatrixSectionProps } from "../components/rebrand/sections/ComparisonMatrixSection/ComparisonMatrixSection";
import { Box } from "../components/rebrand/core/box";
import type { ZigZagSectionProps } from "../components/rebrand/sections/ZigZag/ZigZag";
import { HeroSectionProps } from "../components/sections/hero";
import { orderedFeatures } from "./pricing/features";
import { orderedPlans } from "./pricing/plans";

export const VendingData: HeroSectionProps = {
    size: "large",
    title: "Vending Machine",
    subtitle:
      "Diseñado para la creación de puntos de venta con autoservicio o venta asistida que permite el control y supervisión mediante una aplicación web y móvil, gracias a la conectividad IoT.\n\nAyuda a crea un ecosistema de venta 360º que permite visualizar aspectos técnicos y comerciales, planificación y abastecimiento de productos en tiempo real, adaptado según las necesidades de cada empresa.",
    graphics: (
      <Box width={["100%", "100%", "450px", "600px"]} paddingXSpacing={[0, 8, 0]}>
        <HeroGraphic />
      </Box>
    ),
    graphicsPosition: "right",
    gradient: {
      main: {
        backgroundColor: "transparent",
      },
      fade: {
        backgroundColor: "transparent",
      },
    }
};
export const ZigZagSectionDataVendingMachine: ZigZagSectionProps = {
    header: {
      title: "Descubre el resultado de incorporar IoT a una Vending",
      subtitle:
        "Un producto diseñado para la creación de puntos de venta con autoservicio o venta asistida que permite el control y supervisión mediante una aplicación web y móvil, gracias a la conectividad loT.",
    },
    initialAlign: "right",
    features: [
      {
        title: "KPI de ventas y gestión",
        subtitle:
          "Texto",
        media: {
          graphics: "connectRemote",
        },
      },
      {
        title: "Control y actualizaciones remotas",
        subtitle:
          "Texto",
        media: {
          graphics: "client",
        },
      },
      {
        title: "Escalabilidad y adaptabilidad",
        subtitle:
          "Texto",
        media: {
          graphics: "engineering",
        },
      },
    ],
  };


export const IoTMeterData: HeroSectionProps = {
    size: "large",
    title: "IoT Meter",
    subtitle:
        "Blah blah blah....",
    graphics: (
        <Box width={["100%", "100%", "450px", "600px"]} paddingXSpacing={[0, 8, 0]}>
        <HeroGraphic />
        </Box>
    ),
    graphicsPosition: "right",
    gradient: {
        main: {
        backgroundColor: "transparent",
        },
        fade: {
        backgroundColor: "transparent",
        },
    },
};
export const ZigZagSectionDataIoTMeter: ZigZagSectionProps = {
    header: {
        title: "Controla y supervisa mediciones en terreno",
        subtitle:
        "Diseñado y optimizado con sensores capaces de leer con precisión el consumo de energía y/o agua con monitoreo de forma remota mediante distintos enlaces de comunicación mediante WIFI, lo cuál reducirá tiempo en procesos operativos y administrativos. Además de tomar decisiones óptimas basadas en big data e inteligencia artificial, debido a que puede conocer y proyectar curvas de consumo mensual y en diferentes periodos de tiempo."
    },
    initialAlign: "right",
    features: [
        {
        title: "Adquisición certera variables fisicas",
        subtitle:
            "Texto",
        media: {
            graphics: "connectRemote",
        },
        },
        {
        title: "Control y actualizaciones remotas",
        subtitle:
            "Texto",
        media: {
            graphics: "client",
        },
        },
        {
        title: "Reportes y KPI personalizables",
        subtitle:
            "Texto",
        media: {
            graphics: "engineering",
        },
        },
    ],
};

export const PlannycsData: HeroSectionProps = {
    size: "large",
    title: "Plannycs",
    subtitle:
        "Software en modalidad SaaS que permitirá supervisar y lograr eficiencia en la mantención y seguimiento de trabajos en terreno mediante Ordenes de Trabajo",
    graphics: (
        <Box width={["100%", "100%", "450px", "600px"]} paddingXSpacing={[0, 8, 0]}>
        <HeroGraphic />
        </Box>
    ),
    graphicsPosition: "right",
    gradient: {
        main: {
        backgroundColor: "transparent",
        },
        fade: {
        backgroundColor: "transparent",
        },
    },
};
export const ZigZagSectionDataPlannycs: ZigZagSectionProps = {
    header: {
        title: "Gestiona y Optimiza tus trabajos en terreno",
        subtitle:
        "Un programa diseñado para empresas dedicadas a la mantención industrial, con el fin de identificar puntos de deterioro y/o corregir fallas presentadas en equipos industriales, gracias a soluciones de mantenimiento preventivo y correctivo basados en Al, controlado por una aplicación móvil que permite escanear un código QR en cada objetos a evaluar, facilitando las labores técnicas en terreno, lo cual permite dar una solución de manera óptima y en consecuencia con un bajo impacto a nivel productivo."
    },
    initialAlign: "right",
    features: [
        {
        title: "Adquisición certera variables fisicas",
        subtitle:
            "Texto",
        media: {
            graphics: "connectRemote",
        },
        },
        {
        title: "Control y actualizaciones remotas",
        subtitle:
            "Texto",
        media: {
            graphics: "client",
        },
        },
        {
        title: "Reportes y KPI personalizables",
        subtitle:
            "Texto",
        media: {
            graphics: "engineering",
        },
        },
    ],
};


// Tabla compraradora de vending
export type TypeVendingEntry = "Básica" | "Autónoma" | "Autoservicio";
export const comparisonMatrixDataVending: ComparisonMatrixSectionProps = {
  plans: orderedPlans,
  features: orderedFeatures,
  firstColumnPercentageWidth: 28,
};